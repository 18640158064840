/* Typography */

@font-face {
  font-family: "Akkurat Pro Light";
  src: url("../assets/fonts/AkkuratProLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Akkurat Pro Regular";
  src: url("../assets/fonts/AkkuratProRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Akkurat Pro Bold";
  src: url("../assets/fonts/AkkuratProBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Campton Book";
  src: url("../assets/fonts/CamptonBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Campton Bold";
  src: url("../assets/fonts/CamptonBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Campton Extra Bold";
  src: url("../assets/fonts/CamptonExtraBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Campton Extra Light";
  src: url("../assets/fonts/CamptonExtraLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Default */

a:-webkit-any-link {
  color: inherit;
  cursor: inherit;
  text-decoration: none;
}

a.mailto {
  color: #4e2a84;
  cursor: pointer;
  font-family: "Campton Book";
}

a.nav-link {
  color: #4e2a84;
  font-family: "Campton Book";
  cursor: pointer;
}

body {
  color: #342f2e;
  font-family: "Campton Extra Light";
  padding: 0;
  margin: 0;
}

.main-content-outer {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.main-content-outer.align-middle {
  justify-content: center;
  align-items: center;
}

.main-content-inner {
  padding-top: 72px;
  padding-bottom: 100px;
}

.main-content-inner.constrain-600 {
  max-width: 600px;
  margin: 80px auto;
}

.main-content-inner.constrain-1260 {
  width: 1260px;
  max-width: 100%;
  margin: 0 auto;
}

ul {
  margin: 0;
  padding-inline-start: 32px;
}

textarea {
  resize: none;
}

button {
  padding: 0;
  text-decoration: none;
  background: #4e2a84;
  border: 1px solid #4e2a84;
  color: #fff;
  font-family: "Akkurat Pro Bold";
  font-size: 16px;
  text-transform: uppercase;
  height: 48px;
  width: 360px;
  max-width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: background 0.35s, color 0.35s, border 0.35s;
  transition: background 0.35s, color 0.35s, border 0.35s;
}

button:hover {
  background: #b6acd1;
  border: 1px solid #b6acd1;
  -webkit-transition: background 0.4s, color 0.4s, border 0.4s;
  transition: background 0.4s, color 0.4s, border 0.4s;
}

button:active {
  background: white;
}

:focus {
  outline: none;
}

button.btn-sm {
  width: 120px;
}

button.secondary {
  color: #4e2a84;
  border: 1px solid #4e2a84;
  background-color: white;
}

button.secondary:hover {
  background-color: #ddd;
}

button.secondary:active {
  background: #b6acd1;
}

.btn-group {
  text-align: center;
}

.btn-group.two button {
  margin: 0 12px;
}

.hero-img {
  position: relative;
  width: 100%;
  height: 520px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-img-title-wrap {
  position: relative;
  text-align: center;
}

.hero-img-title {
  font-size: 60px;
  font-family: "Campton Bold", sans-serif;
  color: #fff;
  text-shadow: 2px 2px 0px #000;
  margin: 0 0 1rem 0;
  position: relative;
}

.hero-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.input-icon-wrap {
  position: relative;
  height: 45px;
}

.input-icon-wrap .input-icon {
  position: absolute;
  display: flex;
  align-items: center;
  height: 45px;
  left: 8px;
}

.input-icon-wrap input:not([type="radio"]) {
  padding-left: 32px;
}

.bold {
  font-family: "Akkurat Pro Bold";
}

/* Header */

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background-color: #401f68;
  z-index: 1001;
}

.hamburger-wrapper {
  position: absolute;
  left: 0;
}

.btn-hamburger {
  color: white;
  font-size: 32px;
  margin: 20px;
  cursor: pointer;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.btn-hamburger:hover {
  color: #ddd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.btn-hamburger:active {
  color: #836eaa;
}

.header-title {
  text-transform: uppercase;
  color: white;
  font-family: "Akkurat Pro Bold";
  font-size: 20px;
}

/* Menu */

.menu-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  background-image: linear-gradient(to right, #4e2a84, #6033a2);
  overflow-y: scroll;
  z-index: 1003;
}

.menu-container.hide {
  left: -400px;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.menu-container.show {
  left: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.menu-container-inner {
  color: white;
  padding: 40px;
}

.greeting-wrap {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
  display: flex;
}

.user-name {
  font-family: "Campton Book";
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.user-title {
  font-family: "Campton Extra Light";
  font-size: 14px;
}

.user-photo {
  height: 60px;
  border-radius: 50%;
  margin-right: 12px;
}

.menu-nav-item-wrap {
  position: relative;
  margin-bottom: 32px;
}

.menu-nav-label {
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Akkurat Pro Regular";
  cursor: pointer;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.menu-nav-label:hover {
  color: #ddd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.menu-nav-item-wrap a::before {
  content: " ";
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: #fff;
  left: 0;
  transition: 0.35s;
}

.menu-nav-item-wrap a.active::before {
  opacity: 1;
}

.menu-nav-item-wrap a.active .menu-nav-label {
  margin-left: 16px;
}

.screendoor {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1002;
}

.screendoor.hide {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.screendoor.show {
  visibility: visible;
  opacity: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.btn-menu-expand {
  margin-left: 4px;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.menu-nav-rollup-container {
  max-height: 0;
  opacity: 0;
  padding-left: 12px;
  overflow: hidden;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.menu-subnav-item-wrap {
  position: relative;
  margin: 20px 0;
}

.menu-subnav-item-wrap a.active .menu-subnav-label {
  margin-left: 16px;
}

.menu-subnav-label {
  font-family: "Akkurat Pro Light";
  font-size: 16px;
  text-transform: none;
  cursor: pointer;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.menu-subnav-label:hover {
  color: #ddd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.menu-nav-item-wrap.expand .menu-nav-rollup-container {
  max-height: 500px;
  opacity: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.menu-nav-item-wrap.expand .btn-menu-expand {
  transform: rotate(-180deg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Index */

.hero-img.index {
  background: url("../assets/backgrounds/hero.jpg");
  background-size: cover !important;
  background-position: center !important;
}

.index-content-container {
  margin: 40px 0;
}

/* Tools */

.tool-title {
  font-family: "Akkurat Pro Regular";
  font-size: 36px;
  color: #401f68;
  text-align: center;
  margin-bottom: 40px;
}

.tool-description-label {
  font-family: "Akkurat Pro Bold";
  margin-bottom: 8px;
}

.tool-description-container {
  margin: 0 auto;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

.tool-description {
  line-height: 28px;
}

.tool-feature-list {
  margin-top: 20px;
}

.tool-feature-label {
  font-family: "Akkurat Pro Bold";
  margin-bottom: 8px;
}

.tool-feature-list ul li {
  line-height: 28px;
}

.form-wrap {
  margin: 40px 0;
}

.tool-btn-wrap {
  margin-bottom: 40px;
}

textarea {
  width: calc(100% - 24px);
  height: 120px;
  overflow: scroll;
  padding: 12px;
  font-family: "Campton Extra Light";
  margin-bottom: 8px;
}

.form-label {
  font-family: "Akkurat Pro Bold";
  margin-bottom: 8px;
}

.form-label.static {
  display: inline-block;
  min-width: 80px;
}

/* App Menu */

.tool-menu-container {
  position: fixed;
  left: 0;
  top: 72px;
  height: 72px;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.95);
  overflow-x: scroll;
  white-space: nowrap;
  text-align: center;
  z-index: 1001;
}

.tool-menu-container .menu-elem {
  display: inline-block;
  padding: 0 12px;
  min-width: 160px;
  line-height: 72px;
  color: #401f68;
  font-family: "Akkurat Pro Regular";
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.35s;
}

.tool-menu-container .menu-elem:hover {
  background-color: #e3e3e3;
  transition: 0.4s;
}

.tool-menu-container a.active .menu-elem {
  background-color: #836eaa;
  color: white;
}

/* Application */

.app-content-container {
  margin-top: 72px;
  padding: 80px 0;
}

.app-content-container.constrain-600 {
  width: 600px;
  max-width: 100%;
}

/* Tables */

.table-container {
  margin: 40px 0;
}

.sort-table-wrap {
  overflow-x: scroll;
}

.ut-table {
  border-collapse: collapse;
  min-width: 100%;
}

.ut-table .ut-table-header[data-sticky-td="true"] {
  box-shadow: 0px 1px 0px 1px white;
}

.ut-table .ut-table-cell[data-sticky-td="true"] {
  box-shadow: 0px 1px 0px 1px #ddd;
}

.ut-table-header {
  background-color: #836eaa;
  color: white;
  padding: 12px;
  border: 1px solid #ddd;
  white-space: nowrap;
}

.ut-table-cell {
  padding: 12px;
  background-color: white;
  border: 1px solid #ddd;
}

.ut-table-sort-icon {
  margin-left: 4px;
}

.ut-table-header.sorted {
  font-family: "Akkurat Pro Bold";
}

.ut-table-row:nth-of-type(even) td {
  background-color: #f8f8f8;
}

.btn-add-message-wrap {
  text-align: center;
  margin: 20px 0;
}

.ut-table-action-icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #401f68;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.35s;
}

.ut-table-action-icon-wrap:nth-of-type(1) {
  margin-bottom: 8px;
}

.ut-table-action-icon-wrap:hover {
  background-color: #836eaa;
  transition: 0.4s;
}

.ut-table-action-icon-wrap:active {
  background-color: white;
}

.ut-table-cell.paragraph {
  line-height: 28px;
}

.ut-table-cell.date {
  white-space: nowrap;
}

.ut-table-icon-edit {
  margin-left: 3px;
  margin-bottom: 3px;
}

/* Loader */

.loader-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.loader-wrap {
  text-align: center;
}

.loading-message {
  color: #401f68;
  font-family: "Akkurat Pro Light";
  margin-bottom: 8px;
}

.loading-icon {
  color: #401f68;
  font-size: 20px;
}

/* Modal */

.modal-inner {
  position: relative;
}

.modal-close-wrap {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 20px;
}

.modal-heading {
  font-size: 20px;
  text-align: center;
  font-family: "Akkurat Pro Regular";
}

.modal-body-container {
  margin: 40px 0;
}

.unsaved-message {
  text-align: center;
  margin-bottom: 20px;
  color: red;
}

.form-group {
  margin: 40px 0;
}

.form-group .static-input {
  display: inline-block;
}

.react-datepicker-wrapper {
  width: 100%;
}

input:not([type="radio"]) {
  color: #716c6b;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  height: 45px;
  display: inline-block;
  border: 1px solid #ccc;
  font: inherit;
  color: #342f2e;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  padding: 0.5em;
  transition: 0.35s linear border;
}

textarea {
  color: #716c6b;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  height: 120px;
  display: inline-block;
  border: 1px solid #ccc;
  font: inherit;
  color: #342f2e;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  padding: 0.5em;
  transition: 0.35s linear border;
}

.custom-dd-wrap select,
.react-datepicker__input-container input {
  background: #fff
    url(https://common.northwestern.edu/v8/css/images/icons/arrow-down-dkpurple.svg)
    no-repeat top 50% right 0.5rem/17px 10px;
  color: #716c6b;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  height: 45px;
  display: inline-block;
  border: 1px solid #ccc;
  font: inherit;
  color: #342f2e;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  padding: 0.5em;
  transition: 0.35s linear border;
  cursor: pointer;
}

.btn-processing {
  margin-left: 4px;
}

.submit-msg {
  text-align: center;
  margin: 20px 0;
}

.submit-msg.error {
  color: red;
}

.btn-add-message {
  margin-right: 8px;
}

.bg-color-wrap {
  display: flex;
  margin-top: 12px;
}

.bg-color-preview {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
  margin-left: 8px;
  transition: 0.35s;
}

.bg-color-preview.color-warning {
  background-color: #f0ad4e;
}

.bg-color-preview.color-info {
  background-color: #5bc0de;
}

.bg-color-preview.color-danger {
  background-color: #d9534f;
}

.user-loader {
  margin-bottom: 40px;
}

/* Role Table Cell */

.modal-role-wrap {
  color: #716c6b;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  height: 45px;
  border: 1px solid #ccc;
  font: inherit;
  color: #342f2e;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  padding: 0.5em;
  transition: 0.35s linear border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.modal-role-wrap:hover {
  border: 1px solid #6033a2;
  color: #6033a2;
  transition: 0.4s;
}

.modal-role-remove {
  margin-top: 6px;
  font-size: 20px;
}

.modal-role-add {
  margin-right: 8px;
  display: inline-block;
}

.role-cell-wrap {
  display: flex;
  margin-bottom: 8px;
}

.role-cell-wrap:last-of-type {
  margin-bottom: 0;
}

.role-cell-app-name {
  text-transform: uppercase;
}

.role-cell-permission {
  margin-left: 4px;
}

.no-roles-msg {
  text-align: center;
  margin: 20px 0;
  color: red;
}

/* Table Pagination */

.pagination-container,
.export-container {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.ut-table-pagination-icon-wrap {
  margin: 0 4px;
}

.ut-table-pagination-icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #401f68;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.35s;
}

.ut-table-pagination-icon-wrap:hover {
  background-color: #836eaa;
  transition: 0.4s;
}

.ut-table-pagination-icon-wrap:active {
  background-color: white;
}

.ut-table-pagination-icon-wrap.disabled {
  cursor: default;
  background-color: #ddd;
}

.pagination-actions-wrap {
  display: flex;
  margin: 0 20px;
}

.pagination-summary {
  padding: 12px 0;
}

.filtering-container {
  margin: 20px 0;
  text-align: center;
}

.filter-inputs-wrap {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.filter-inputs-wrap.one {
  justify-content: center;
}

.filter-inputs-wrap.one > * {
  width: 360px;
}

.filter-inputs-wrap > * {
  width: 50%;
}

.filter-inputs-wrap > *:first-of-type {
  margin-right: 12px;
}

.filter-inputs-wrap > *:last-of-type {
  margin-left: 12px;
}

.filter-title {
  font-family: "Akkurat Pro Bold";
  margin-bottom: 20px;
}

/* FAOAdmin */

.modal-title-group {
  margin: 40px 0;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

.modal-title-wrap {
  position: relative;
  padding: 20px 12px;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin-bottom: 12px;
}

.btn-title-remove {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 24px;
  color: red;
  background-color: white;
  cursor: pointer;
}

.modal-title-add-wrap {
  text-align: center;
}

.react-select-chevron {
  font-size: 18px;
}

.text-add-end-date {
  color: #4e2a84;
  font-family: "Akkurat Pro Bold";
  cursor: pointer;
}

/* Logout */

.logout-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logout-message-wrap {
  text-align: center;
}

.logout-message {
  font-size: 1.2em;
  color: #4e2a84;
  font-family: "Akkurat Pro Regular";
  margin-bottom: 12px;
}

/* Professorship Admin */

.professorship-admin-container .filtering-container {
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

/* Unauthorized */

.unauthorized-msg {
  padding: 0 12px;
  text-align: center;
  max-width: 400px;
}

/* Faculty Table Adjustments */

.faculty-title-cell-wrap {
  display: flex;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
}

.faculty-title-cell-name {
  font-family: "Campton Book";
  margin-right: 8px;
}

/* Privilege Location Admin */

.privilege-location-container .filtering-container {
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

/* Faculty Note Types Admin */

.faculty-admin-container .filtering-container {
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

/* Screen Message */

.screen-msg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(256, 256, 256, 0.8);
  z-index: 2001;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Campton Bold";
  font-size: 1.2em;
}

/* User Search Modal */

.access-type-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.access-type-wrap {
  margin: 0 12px;
  position: relative;
}

.access-type-wrap.active {
  font-family: "Campton Book";
}

.access-type-wrap.active::after {
  content: " ";
  height: 40px;
  width: 1px;
  position: absolute;
  top: 22px;
  left: 50%;
  background-color: #4e2a84;
}

.access-container {
  border: 1px solid #ddd;
  padding: 32px 20px;
  margin: 20px 0;
  border-radius: 12px;
}

.access-select-wrap {
  margin-top: 20px;
}

.access-type-label {
  font-family: "Campton Book";
  color: #4e2a84;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
}

/* Coming Soon */

.coming-soon-container {
  text-align: center;
}

/* Title Groups Admin */

.title-groups-container .filtering-container {
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

/* Tablet Breakpoint */

@media only screen and (max-width: 1260px) {
  .index-content-container {
    padding: 0 20px;
  }

  .app-content-container {
    padding: 20px 12px;
  }
}

/* Mobile Breakpoint */

@media only screen and (max-width: 767px) {
  .main-content-inner.constrain-600 {
    margin: 40px auto;
    width: calc(100% - 24px);
  }

  .hero-img {
    height: 300px;
  }

  .main-content-inner {
    padding-top: 46px;
  }

  .header-container {
    height: 48px;
  }

  .btn-hamburger {
    font-size: 24px;
    margin: 12px;
  }

  .menu-container {
    width: calc(80% - 40px);
  }

  .menu-container.hide {
    left: calc(-80% + 40px);
  }

  .tool-menu-container {
    top: 46px;
    height: 46px;
  }

  .tool-menu-container .menu-elem {
    line-height: 46px;
    font-size: 12px;
    min-width: 120px;
  }

  .app-content-container {
    margin-top: 46px;
    padding: 20px 12px;
  }

  .main-content-inner.constrain-1260 {
    width: 100%;
  }

  .tool-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .tool-description,
  .tool-feature-list ul li {
    font-size: 14px;
    line-height: 24px;
  }

  .tool-description-container {
    padding-bottom: 20px;
  }

  .table-container {
    margin: 20px 0;
  }

  .ut-table {
    font-size: 12px;
  }

  .ut-table-cell.paragraph {
    line-height: 20px;
  }

  .ut-table-action-icon-wrap {
    width: 32px;
    height: 32px;
  }

  .filter-inputs-wrap {
    display: block;
  }

  .filter-inputs-wrap.one {
    display: flex;
  }

  .filter-inputs-wrap.one .input-icon-wrap {
    margin: 0;
  }

  .filter-inputs-wrap > * {
    width: 100%;
  }

  .filter-inputs-wrap > *:first-of-type,
  .filter-inputs-wrap > *:last-of-type {
    margin: 0 0 12px 0;
  }

  .professorship-admin-container .filtering-container {
    padding-bottom: 20px;
  }

  .filter-dd-wrap .form-group {
    width: 100%;
  }
}
